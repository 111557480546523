import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import axios from 'axios';
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import { Button, Form, Container, Row, Col, Fade, Checkbox } from "react-bootstrap";
import SearchList from "../../components/Vacancies/SearchList/SearchList";
import VacancyCategories from '../../components/Vacancies/VacancyCategories/VacancyCategories';
import logoBlack from "../../images/logo-dark.svg";
import { urlTrim } from "../../components/common/site/functions";
import SeoStructuredData from "../../components/Seo/StructuredData"
import ScrollAnimation from 'react-animate-on-scroll';
import BrandLogoDark from "../../images/logo-dark.png";
import moment from "moment";
import _ from "lodash"
import "./Vacancies.scss"



const Vacancies = (props) => {
  const data = useStaticQuery(graphql`
  query {

    allBranch(filter: {publish: {eq: true}}) {
      nodes {
        branch_id
        branch_name
        Banner_Image {
          alternativeText
          url
        }
        image {
          url
        }
      }
    }
    
    allJobCategories {
      edges {
        node {
          Name
          URL
          Image {
            url
            alternativeText
          }
        }
      }
    }
    
    allJob(filter: {publish: {eq: true}}) {
      totalCount
      edges {
        node {
          area_name
          branch_id
          brand_id
          _id
          crm_provider
          vacancy_category
          vacancy_location
          vacancy_type
          vacancy_title
          vacancy_full_time
          vacancy_salary_text
          vacancy_closing_date
          vacancy_id
          vacancy_short_description
        }
      }
    }

    glstrapi {
      globalModule {
        Job_Listing_Page_Adverts {
          Content
          Title
          Value
          CTA_Label
          CTA_Link {
            id
          }
        }
      }
    }
  }
`);



  const [loading, setLoading] = useState(false)


  const jobs = data.allJob.edges;
  const branches = data.allBranch.nodes;
  const jobCategories = data.allJobCategories.edges;
  const adverts = data.glstrapi.globalModule.Job_Listing_Page_Adverts;
  const cloud_url = process.env.GATSBY_CLOUD_URL;
  var jobs_list_by_categories = _.uniqBy(jobs, (e) => e.vacancy_category);
  var sortOrder = ['Sales',
    'Lettings',
    'Land & New Homes',
    'Admin & Support',
    'Training Department',
    'Financial Services',
    'Call Centre',
    'Weekend and Part - time'];

  var sortPeoples = jobs_list_by_categories.sort((a, b) => sortOrder.reverse().indexOf(b.vacancy_category) - sortOrder.indexOf(a.vacancy_category));
  // console.log('sortPeoples', sortPeoples)
  var jobs_list = [];

  if (props.category) {
    let list = _.filter(jobs, ({node}) => (props.category.slug === urlTrim(node.vacancy_category.replace("Fine & Country","Sales").replace("Land & New Homes","Sales").replace("Residential","Sales").replace("Call Centre","Sales").replace("Call Centre","Sales").replace("Training","administration-and-support").replace("Head Office","administration-and-support").replace("Client Liaison","administration-and-support")) && (moment(node.vacancy_closing_date != null ? node.vacancy_closing_date : "2100-01-01").isAfter(moment().format('YYYY-MM-DD'), 'day'))));
    if (props.category?.slug === "vacancies") {
      list = _.filter(jobs, ({node}) => ((moment(node.vacancy_closing_date != null ? node.vacancy_closing_date : "2100-01-01").isAfter(moment().format('YYYY-MM-DD'), 'day'))));
    } 
    jobs_list = _.orderBy(list, ['vacancy_priority', 'createdAt'], ['asc', 'desc']);
  }



  if (props.category !== null) {
    return (
      <React.Fragment>
        <div className="search-result-block">
          <SeoStructuredData
            type="SearchResultsPage"
            publisher={{ name: "Career", logo: cloud_url + '/images/mail-logo.png' }}
            entity={
              {
                name: "Current job vacancies",
                desc: "View current vacancies",
                list: _.map(jobs_list, o => _.extend({ name: o.vacancy_title, url: `/vacancy/${o.vacancy_id}` }, o))

              }
            }
          />
          <SearchList jobs={jobs_list} branches={branches} adverts={adverts} />
        </div>
      </React.Fragment>
    )
  } else if (props.category === null) {
    return (
      <React.Fragment>
        <VacancyCategories jobs={jobCategories} branches={branches} />
      </React.Fragment>
    )
  } else {
    return null
  }

}

export default Vacancies;
