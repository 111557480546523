import React from "react"
import { useMatch } from "@reach/router"
import SEO from "../components/seo"
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import Banner from "../components/Home/Banner/HomeBanner"
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"
import VacancyListBreadcrumb from "../components/Breadcrumbs/VacancyListBreadcrumb"
import LandingBanner from "../components/Home/Banner/LandingBanner"
import About from "../components/Home/About/About"
import StaticContent from "../components/StaticContent/StaticContent"
import StaticContentSidebar from "../components/StaticContent/StaticContentSidebar"
import BookValuation from "../components/Home/BookValuation/BookValuation"
import FormBlock from "../components/Home/FormBlock/FormBlock"
import ServiceContentOne from "../components/ServiceContentBlock/ServiceContentOne"
import ServiceContentTwo from "../components/ServiceContentBlock/ServiceContentTwo"
import CountBlock from "../components/Home/CountBlock/CountBlock"
import GlobalCount from '../components/Home/CountBlock/GlobalCount';
import Accreditations from "../components/Accreditations/Accreditations"
import StaffStory from "../components/StaffStory"
import FAQSection from "../components/FAQ/FAQSection"
import Vacancies from "../components/Vacancies/Vacancies"
import AllVacancies from "../components/Vacancies/AllVacancies"
import Benefits from "../components/Benefits/Benefits"
import OurNetwork from "../components/Home/OurNetwork/OurNetwork"
import FamilyOfBrands from "../components/FamilyOfBrands/FamilyOfBrands"
import FAQComponent from "../components/FAQ/FAQComponent"
import GuidesSection from "../components/Guides/HandyGuides"
import ContactLanding from "../components/ContactLanding/ContactLanding"
import NewsInsights from '../components/Home/NewsInsights/NewsInsights';
import NewsLanding from '../components/News/NewsLanding';


import { VACANCIES_PAGE_URL } from "../components/common/site/constants";

const Modules = props => {

  const vacanciesList = useMatch(VACANCIES_PAGE_URL.alias + "/:slug")
  return (
    <React.Fragment>
      <SEO
        title={props.page.Meta_Title}
        description={props.page.Meta_Description}  image={props.page.Banner_Image ? props.page.Banner_Image.url : ''}
      />
      <div
        className={
          props.page.Layout === "Homepage"
            ? "home-header"
            : props.page.Layout === "Default"
              ? "wrapper area-guide-wrapper"
              : props.page.Layout === "Staticpage" || props.page.Layout === "Staticpage_Sidebar" || props.page.Layout === "Calculator_or_Form" ||
                props.page.Layout === "Background_Image_Layout"
                ? "wrapper static-page"
                : ""
        }
      >
        <Header />

        {props.page.Layout === "Homepage" &&
          props.page.Banner_Image &&
          props.page.Choose_Menu[0].URL === "home" && (
            <Banner {...props.page} />
          )}

        {props.page.Layout === "Homepage" &&
          props.page.Banner_Image &&
          props.page.Choose_Menu[0].URL !== "home" && (
            <Banner {...props.page} />
          )}
        {props.page.Layout === "Default" && props.page.Banner_Image && (
          <>
            {/* <Breadcrumbs {...props.page} /> */}
            <LandingBanner {...props.page} />
          </>
        )}
        {(props.page.Layout === "Staticpage" &&
          props.page.Banner_Image === null && vacanciesList === null) &&
          <Breadcrumbs {...props.page} />

        }
        {(props.page.Layout === "Staticpage" &&
          props.page.Banner_Image === null && vacanciesList !== null) &&
          <VacancyListBreadcrumb Pagename={vacanciesList.slug} />
        }
        {(props.page.Layout === "Staticpage_Sidebar" || (props.page.Layout === 'Calculator_or_Form' && props.page.Banner_Image)) &&
          <Breadcrumbs {...props.page} />
        }
        {props.page.Layout === 'Default' && props.page.Banner_Image === null &&
          <>
            <Breadcrumbs {...props.page} />
          </>
        }
      </div>
      {props.modules &&
        props.modules.map((module, i) => {
          return (
            <div key={i}>
              {module.Intro_Content && <About {...module} />}
              {module.Add_Static_Content && (
                <>
                  {module.Style == "With_Sidebar" ? (
                    <StaticContentSidebar
                      {...module}
                      {...props.page}
                      count={i}
                    />
                  ) : (
                    <StaticContent {...module} style={module.Style} count={i} vacanciesList={vacanciesList} />
                  )}
                </>
              )}
              {module.Select_Module &&
                module.Select_Module.CTA_1_Link == null && (
                  <FormBlock {...module.Select_Module} />
                )}

              {module.Select_Module && module.Select_Module.CTA_1_Link && (
                <BookValuation {...module.Select_Module} />
              )}
              {/**Service content block*/}
              {module.Add_New_Block && (
                <>
                  {module.Add_New_Block && module.Add_New_Block.length !== 2 &&
                    module.Add_New_Block.slice(0, 1).map((service, i) => {
                      return (
                        <ServiceContentOne
                          {...service}
                          length={module.Add_New_Block.length}
                          key={i} />
                      )
                    })}
                  {module.Add_New_Block.length > 1 && (
                    <ServiceContentTwo {...module} />
                  )}
                </>
              )}

              {/* Our Network */}
              {module.Choose_Module === "Our_Network" && <OurNetwork />}

              {/* Benefits block */}
              {module.Add_Benefit && <Benefits {...module} />}
              {/**Statistics block*/}
              {module.Add_Stats && <CountBlock {...module} />}
              {module.Stats_Title && <GlobalCount {...module} />}
              {/**Staff story block*/}
              {module.Add_Staff_Stories && <StaffStory {...module} />}
              {/**FAQ block*/}

              {/* Collection Block */}
              {module.Select_Collection === "FAQs" && (
                <FAQComponent {...module} />
              )}
              {/* End of Collections Block */}

              {module.Select_FAQs && <FAQSection {...module} />}

              {/* Family_Of_Brands_Section block */}
              {module.Family_Of_Brands_Section && (
                <FamilyOfBrands {...module} />
              )}
              {/**Global module*/}
              {module.Choose_Module === "Vacancies" && <AllVacancies />}
              {module.Choose_Module === "Vacancy_Categories" && <Vacancies category={vacanciesList} />}
              {module.Choose_Module === "Contact_Form" && <ContactLanding {...module} page={props.page} />}
              {/* Guides */}
              {module.Select_Guides_here && <GuidesSection {...module} />}
              {/* News & Latest_News  */}
              {module.Select_Collection === "News" &&
                <NewsLanding />
              }
              {module.Latest_News_Heading && module.Latest_News_Intro_Content &&
                <NewsInsights {...module} />
              }
              {module.Add_Accreditation &&
                  <Accreditations {...module} />
              }
            </div>
          )
        })}
      <Footer />
    </React.Fragment>
  )
}
export default Modules
