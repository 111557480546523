import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Button, Form, Container, Row, Col, Fade, Checkbox } from "react-bootstrap";
import SearchList from "../../components/Vacancies/SearchList/SearchList";
import VacancyCategories from '../../components/Vacancies/VacancyCategories/VacancyCategories';
import { urlTrim } from "../../components/common/site/functions";
import SeoStructuredData from "../../components/Seo/StructuredData"
import ScrollAnimation from 'react-animate-on-scroll';
import BrandLogoDark from "../../images/logo-dark.png";
import moment from "moment";
import _ from "lodash"
import "./Vacancies.scss"
const AllVacancies = (props) => {
  const data = useStaticQuery(graphql`
  query {
    allJob(filter: {publish: {eq: true}}) {
      nodes {
        id
        branch_id
        vacancy_category
        vacancy_id
        vacancy_location
        vacancy_long_description
        vacancy_priority
        vacancy_salary_text
        vacancy_short_description
        vacancy_title
        vacancy_type
        vacancy_weekend_only
        video_id
        vacancy_hours_per_week
        vacancy_full_time
        vacancy_priority
        vacancy_closing_date
      }
    }
    allBranch(filter: {publish: {eq: true}}) {
      nodes {
        branch_id
        Banner_Image {
          alternativeText
          url
        }
        image {
          url
        }
      }
    }
  }
`);
  const jobs = data.allJob.nodes;
  const branches = data.allBranch.nodes;
  const cloud_url = process.env.GATSBY_CLOUD_URL;
  var jobs_list = [];


  let list = _.filter(jobs, (e) => ((moment(e.vacancy_closing_date).isAfter(moment().format('YYYY-MM-DD'), 'day'))));
  jobs_list = _.orderBy(list, ['vacancy_priority', 'createdAt'], ['asc', 'desc']);

  return (
    <React.Fragment>
      <div className="search-result-block">
        <SeoStructuredData
          type="SearchResultsPage"
          publisher={{ name: "Career", logo: cloud_url + '/images/mail-logo.png' }}
          entity={
            {
              name: "Current job vacancies",
              desc: "View current vacancies",
              list: _.map(jobs_list, o => _.extend({ name: o.vacancy_title, url: `/vacancy/${o.vacancy_id}` }, o))

            }
          }
        />
        <SearchList jobs={jobs_list} branches={branches} />
      </div>
    </React.Fragment>
  )
}

export default AllVacancies;
