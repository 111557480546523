
import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import _ from "lodash"
function SeoStructuredData({ type, publisher, entity }) {   
  var ldJson = {
    "@context": "http://schema.org",
    "@type": type,
  };

  if (!_.isEmpty(publisher)) {
    ldJson['publisher'] = {
      "@type": "Corporation",
      "name": publisher.name,
      "logo": {
        "@type": "ImageObject",
        "url": publisher.logo,
        "width": 243,
        "height": 34
      }
    }
  }
  if (!_.isEmpty(entity)) {
    var itemListElement = [];
    if (!_.isEmpty(entity.list)) {
      _.forEach(entity.list, (val, key) => {
        itemListElement.push(
          {
            "@type": "ListItem",
            "position": key+1,
            "item": {
              "@type": "ListItem",
              "@id": val.id,
              "url": val.url,
              "name": val.name
            }
          }
        ) 
      }) 
    }

    ldJson['mainEntity'] = {
      "@type": "ItemList",
      "numberOfItems": itemListElement.length,
      "name": entity.name,
      "description": entity.description,
      "itemListElement": itemListElement
    }
  }
  
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(ldJson, null, 2)}
      </script>
    </Helmet>
  )
}


export default SeoStructuredData
