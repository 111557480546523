import React from 'react'
import { Form } from "react-bootstrap"
import Col from "react-bootstrap/Col"


const SelectField = ({ name, required, placeholder, values, handlechange, fieldClass, label, grpmd, componentprops, step, type, id, alias }) => (
    <Form.Group as={Col} md={grpmd} className={`${type} ${step}`} controlId={"validation" + name}>
        {label
            ? <Form.Label className="form-label">{label}{required ? '' : ''}</Form.Label>
            : ''

        }
        <Form.Control
            controlId={id}
            className={fieldClass ? fieldClass + ' form-select' : ' form-select'}
            required={required}
            name={name}
            onChange={handlechange}
            as="select"
        >
            <option value="" key="">{placeholder}</option>
            <option value="Sales">Buying</option>
            <option value="Sales">Selling</option>
            <option value="Letting" selected={alias == "contact-form-landlords"}>Lettings</option>
            <option value="Mortgages" selected={alias == "contact-form-mortgages"}>Mortgages</option>
            <option value="Conveyancing" selected={alias == "contact-form-conveyancing"}>Conveyancing</option>
            <option value="Over 60s Budget Booster">Home For Life Plan</option>
            <option value="Land &amp; New Homes">Land &amp; New Homes</option>
            <option value="Home Insurance">Home Insurance</option>
            <option value="Careers">Careers</option>
            <option value="Other">Other</option>
    </Form.Control>
  </Form.Group >

);

export default SelectField;