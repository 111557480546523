import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import parse from "html-react-parser"
import "../FormStep/ValuationForm.scss"
import ModalTiny from "react-bootstrap/Modal"
import SendYourForm from "../forms/send-your-cv"
import JobAlert from "../forms/job-alert"
import $ from "jquery";
import Contact from "../forms/contact-form"

const ContactLanding = (props) => {

  const [modalCVformOpen, setCVformOpen] = React.useState(false);
  const [modalJobAlertformOpen, setJobAlertformOpen] = React.useState(false);
  const closeCVformModal = () => {
    setCVformOpen(false);
  }
  const closeJobAlertformModal = () => {
    setJobAlertformOpen(false);
  }
  useEffect(() => {
    $(document).on('click', '#pop-up-send-your-cv', () => {
      if (!modalCVformOpen) {
        setCVformOpen(true);
      }
    });
    $(document).on('click', '#pop-up-job-alert', () => {
      if (!modalJobAlertformOpen) {
        setJobAlertformOpen(true);
      }
    });
  })
  return (
    <React.Fragment>
      <section className="valuation-page" style={{ background: "url(" + props.page.Banner_Image.url + ")" }}>
        <div className="page">
          <Container>
            <Row>
              <Col md='12' lg={7} className="heading" >
                {parse(props.page.Banner_Content)}
              </Col>
            </Row>
            <Row>
              <Col md='12' lg={8}>
                <div className="valuation-form contact-main-form">
                  <div className="main-calc">
                    <div className="form-wrapper">
                      <Contact />
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={4} className="d-none d-lg-block">

              </Col>
            </Row>
          </Container>
        </div>
        <ModalTiny show={modalCVformOpen} onHide={closeCVformModal} className="book-a-viewing-modal modal-main-form">
          <ModalTiny.Header closeButton className="contact-close-btn">
            <ModalTiny.Title className="w-100"><h2 className="text-center mb-0"> Send us your CV</h2>
            </ModalTiny.Title>
          </ModalTiny.Header>
          <ModalTiny.Body className="event-section people-details-form-modal"><SendYourForm /></ModalTiny.Body>
        </ModalTiny>
        <ModalTiny show={modalJobAlertformOpen} onHide={closeJobAlertformModal} className="book-a-viewing-modal modal-main-form">
          <ModalTiny.Header closeButton className="contact-close-btn">
            <ModalTiny.Title className="w-100"><h2 className="text-center mb-0"> Job Alert</h2>
            </ModalTiny.Title>
          </ModalTiny.Header>
          <ModalTiny.Body className="event-section people-details-form-modal"><JobAlert /></ModalTiny.Body>
        </ModalTiny>
      </section>
    </React.Fragment >
  )
}

export default ContactLanding