import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form } from "react-bootstrap"
import parse from "html-react-parser"
import ScrollAnimation from 'react-animate-on-scroll';
import { useWindowSize } from '../../hooks/winodw-size'
import PlayVideo from '../../components/Play/CustomVideo';
import { NewsDate } from "../common/site/functions"
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import "./News.scss"
// markup
const News = (props) => {
  const [windowWidth] = useWindowSize();
  const [isPlay, setPlay] = useState(false);
  const [show, setShow] = useState(false);
  const showFilter = () => {
    setShow(!show);
  };
  var main_con = props.Content.substr(0, windowWidth < 1520 ? 600 : 800)
  const image_url = props.Tile_Image?.url;

  let processedImages = JSON.stringify({});
  if (props.imagetransforms?.Tile_Image_Transforms) {
    processedImages = props.imagetransforms.Tile_Image_Transforms;
  }
  return (
    <React.Fragment>
      <div className="news-wrap">
        <Container>
          <Row>
            <div className="row-box flex">
              <Col lg="7" className="pe-lg-0 ps-lg-0 xxl-8">
                <picture className="img-zoom about-block-img">
                  <Link to={props.URL.replace(/[^\w\s]/gi, '-')}>
                    <ImageTransform
                      imagesources={image_url}
                      renderer="srcSet"
                      imagename="all-news.Tile_Image.tile_2"
                      attr={{ alt: props.Tile_Image ? props.Tile_Image.alternativeText : props.Title, class: 'mb-0' }}
                      imagetransformresult={processedImages}
                      id={props._id}
                    />
                    {/* <img src={props.Tile_Image.url} alt={props.Tile_Image.alternativeText} className="mb-0" /> */}
                  </Link>
                </picture>
              </Col>
              <Col lg="5" className="ps-lg-0 xxl-4">
                <div className="news-content">
                  <Link to={props.URL.replace(/[^\w\s]/gi, '-')}>
                    <h3>{props.Title}</h3>
                  </Link>
                  <span className="date-text">{NewsDate(props.Date)}</span>
                  <p className="news-land-main-content">{parse(main_con)}...</p>
                  <Link to={props.URL.replace(/[^\w\s]/gi, '-')} className="btn">
                    {windowWidth <= 768 && 'Find out more'}
                    {windowWidth > 769 && ' Read More'}
                    <i className="icon-arrow"></i></Link>
                </div>
              </Col>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default News