import { Link } from "gatsby"
import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import parse from "html-react-parser"
import { Container, Row, Col } from "react-bootstrap"
import GenerateLink from "../common/site/generate-link"
import "./FamilyOfBrands.scss"
import ArunEstates from "../../images/area-guide/arun-estates-dark.svg";
import ArunBrand from "../../images/area-guide/ArunBrand-dark.svg";
import LogoSvg from "../../images/area-guide/logo-svg-dark.svg";
import Mortgage from "../../images/partner/MMD-1.svg";
import Douglas from "../../images/brand-images/DA-1-Web.svg";
import Pittis from "../../images/brand-images/PT-Web.svg";
import Cubbit from "../../images/brand-images/CW-1-Web.svg";
import Wards from "../../images/brand-images/WK-Web.svg";
import { inViewOptions1, contentItemStagger, contentItem, fadeInFromTop } from '../utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
const FamilyOfBrands = props => {
  return (

    <InView {...inViewOptions1}>
      {({ ref, inView }) => (
        <motion.div
          className="family-of-brands"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}
        >
          <Container>
            <Row>
              <Col lg={10} className="heading">
                <span class="sub-title">{props.Brand_Title}</span>
                <h2>{props.Brand_Heading}</h2>
              </Col>
            </Row>

            <Row>
              {props.Family_Of_Brands_Section.map((item, i) => (
                <Col lg={4} md={4} sm={12}>
                  <motion.div key={i} custom={i + 1} variants={fadeInFromTop} className="family-of-brands-section">  
                    <div className="image-section">
                      <div className="our-brand-logo">
                      {item.Brand_Section_Heading == "Cubitt & West" ?
                          <img src={Cubbit} alt="" width={item.Brand_Section_Heading} /> :
                          item.Brand_Section_Heading == "Douglas Allen" ?
                            <img src={Douglas} alt="" width={item.Brand_Section_Heading} /> :
                            item.Brand_Section_Heading == "Pittis" ?
                              <img src={Pittis} alt="" width={item.Brand_Section_Heading} /> :
                              item.Brand_Section_Heading == " Wards" ?
                                <img src={Wards} alt="" width={item.Brand_Section_Heading} /> :
                                item.Brand_Section_Heading == "Arun Land & New Homes" ?
                                  <img src={ArunEstates} alt="" width={item.Brand_Section_Heading} /> :
                                  item.Brand_Section_Heading == "Mortgage Matters Direct" ?
                                    <img src={Mortgage} alt="" width={item.Brand_Section_Heading} /> :
                                    <img src={item.Image.url} alt="" width={item.Brand_Section_Heading} />
                        }
                        {/* {item.Image && <img src={item.Image.url} alt="" />} */}
                      </div>
                    </div>
                    <div className="family-of-brands-content">
                      <div class="family-of-brands-heading">
                        <h4>{item.Brand_Section_Heading}</h4>
                      </div>
                      <div className="family-of-brands-content">
                        <p>{parse(item.Brand_Section_Content)}</p>
                      </div>
                    </div>
                    <div className="family-of-brands-footer">
                      <div>
                        {item.CTA_Label && (
                          <GenerateLink link={item.CTA_Link} class="link-text">
                            {item.CTA_Label}
                            <i className="icon-arrow"></i>
                          </GenerateLink>
                        )}
                      </div>
                    </div>
                  </motion.div>
                </Col>
              ))}
            </Row>
          </Container>
        </motion.div>
      )}
    </InView>
  )
}

export default FamilyOfBrands
