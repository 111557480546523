import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import { Row, Col, Container, Button } from "react-bootstrap";
import Rectangle from "../../../images/arun-team-tile.png"
import { urlTrim } from "../../common/site/functions";
import "./VacancyCategories.scss";
import _ from "lodash";

const VacancyCategories = (props) => {

  const branchImage = (id) => {
    var image = Rectangle;
    // if (!_.isEmpty(id)) {
    //   var branch = _.find(props.branches, { branch_id: id });
    //   if (!_.isEmpty(branch)) {
    //     if (branch.image.length > 0) {
    //       image = branch.image[0].url;
    //     }
    //   }
    // }
    return image;
  }
  return (
    <React.Fragment>
      <div className="vacancy-categories other-areas-block components--Home-GetKnow--GetKnow">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="other-areas-list">
                <Row>
                  {props.jobs && props.jobs.map(({node}, index) => (

                    <Col lg={3} md={6} key={index}>
                      <Link to={`/vacancy-type/${urlTrim(node.URL)}/`} className="category-list">
                        <div className="other-areas-item img-zoom">
                          <div className="other-areas-img">
                            <img src={node.Image.url} alt="img" />
                          </div> 
                            <Link to={`/vacancy-type/${urlTrim(node.URL)}/`} class="btn btn-white">{node.Name} <i className="icon-arrow"></i></Link> 
                        </div></Link>
                    </Col>
                  ))}
                  {/* <Col lg={3} md={6}>
                    <Link to={`/vacancies`} className="category-list">
                      <div className="other-areas-item img-zoom">
                        <div className="other-areas-img">
                          <img src={Rectangle} alt="img" />
                        </div>
                        <Link to={`/vacancies`} class="btn btn-white">View All<i className="icon-arrow"></i></Link>
                      </div></Link>
                  </Col> */}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

    </React.Fragment>
  );
};

export default VacancyCategories;
