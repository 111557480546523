import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Button, Container } from 'react-bootstrap';
import Img from 'gatsby-image';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from 'html-react-parser';
import GenerateLink from "../../common/site/generate-link"
import TrustPilotWidget from "../../TrustPilotWidget"
// Images
import Trustpilot from "../../../images/trustpilot.png"
import GoogleReviews from "../../../images/google-reviews.png"
import PartnerLogo1 from "../../../images/partner/arun-estates.svg"
import PartnerLogo2 from "../../../images/partner/logo-svg.svg"
import PartnerLogo3 from "../../../images/partner/ArunBrandBook.svg"
import PartnerLogo4 from "../../../images/partner/DA-1-Web-White.svg"
import PartnerLogo5 from "../../../images/partner/pittis.svg"
import PartnerLogo6 from "../../../images/partner/MMD-1-white.svg"
import { useWindowSize } from '../../../hooks/winodw-size'
import "./LandingBanner.scss"
// markup
const LandingBanner = (props) => {
  const [windowWidth] = useWindowSize();

  return (
    <React.Fragment>
      <div className="area-guide-banner landing-page-banner components--AreaGuideDetails--AreaGuideBanner--LandingBanner">
        <div className="main-banner-embed">

          {props.Banner_Image &&
            <GatsbyImage image={getImage(props.Banner_Image.url_sharp)} alt={props.Banner_Image.alternativeText} />
            // <Img fluid={props.Banner_Image.url_sharp.childImageSharp.fluid} alt={props.Banner_Image.alternativeText} />
          }

        </div>
        <div className="main-banner-content">
          <Container>
            <div className="banner-text">
              {parse(props.Banner_Content)}
              {(props.Banner_CTA_1_Link && props.Banner_CTA_1_Label) || (props.Banner_CTA_2_Link && props.Banner_CTA_2_Label) ?
                <div className="btn-wrap">
                  {props.Banner_CTA_1_Link && props.Banner_CTA_1_Label &&
                    <GenerateLink link={props.Banner_CTA_1_Link} class="btn btn-secondary">
                      {props.Banner_CTA_1_Label_Heading && <span>{props.Banner_CTA_1_Label_Heading}</span>}
                      {props.Banner_CTA_1_Label}
                    </GenerateLink>
                  }
                  {props.Banner_CTA_2_Link && props.Banner_CTA_2_Label &&
                    <GenerateLink link={props.Banner_CTA_2_Link} class="btn">
                      {props.Banner_CTA_2_Label_Heading && <span>{props.Banner_CTA_2_Label_Heading}</span>}
                      {props.Banner_CTA_2_Label}
                    </GenerateLink>
                  }
                </div> : ''
              }




            </div>
            {props.Show_Reviews &&
              <div className="rating-block">
                <div className='trustpilot-review'>
                  <TrustPilotWidget />
                </div>

                <div className='google-review'>
                  <div className="review-logo">
                    <img src={GoogleReviews} alt="" width="74" />
                  </div>
                  <p>
                    <strong>4.9 out of 5</strong>
                    based on 307 reviews
                  </p>
                </div>
              </div>
            }


            {props.Show_Brands_Logo &&
              <>
                <p className="content-text">{windowWidth <= 768 && 'Part of the Arun Estates family'} {windowWidth > 769 && 'The Arun Estates family'} </p>
                <ul className="partner-logo d-md-flex">
                  <li className="partner-logo1"><a href="https://www.arunestates.co.uk/" target="_blank"><img src={PartnerLogo1} alt="Brand Logo" /></a></li>
                  <li className="partner-logo3"><a href="https://www.cubittandwest.co.uk/" target="_blank"><img src={PartnerLogo3} alt="" /></a></li>
                  <li className="partner-logo4"><a href="https://www.douglasallen.co.uk/" target="_blank"><img src={PartnerLogo4} alt="" /></a></li>
                  <li className="partner-logo5"><a href="https://www.pittis.co.uk/" target="_blank"><img src={PartnerLogo5} alt="" /></a></li>
                  <li className="partner-logo2"><a href="https://www.wardsofkent.co.uk/" target="_blank"><img src={PartnerLogo2} alt="" /></a></li>
                  <li className="partner-logo6"><a href="https://mortgagemattersdirect.co.uk/" target="_blank"><img src={PartnerLogo6} alt="" /></a></li>
                </ul>
                {/* <div className="d-md-none d-block">
                  <ul className="partner-logo d-flex justify-content-between">
                    <li className="partner-logo1 me-0"><img src={PartnerLogo1} alt="" /></li>
                    <li className="partner-logo3 me-0"><img src={PartnerLogo3} alt="" /></li>
                    <li className="partner-logo4 me-0"><img src={PartnerLogo4} alt="" /></li>
                  </ul>
                  <ul className="partner-logo d-flex justify-content-between">
                    <li className="partner-logo5 me-0"><img src={PartnerLogo5} alt="" /></li>
                    <li className="partner-logo2 me-0"><img src={PartnerLogo2} alt="" /></li>
                    <li className="partner-logo6 me-0"><img src={PartnerLogo6} alt="" /></li>
                  </ul>
                </div> */}
              </>
            }

          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}
export default LandingBanner