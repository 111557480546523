import React, { useState } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Form } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby";
import ScrollAnimation from 'react-animate-on-scroll';
import parse from 'html-react-parser';
import GenerateLink from "../../common/site/generate-link"
import MapImg from "../../../images/map.svg"
import Map from "./map"
import "./OurNetwork.scss"
import { inViewOptions, containerVariants, contentItem, fadeInFromTop, titleVariants, contentVariants } from '../../utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
const OurNetwork = () => {

  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        globalModule {
             
            Our_Network_Content
            Our_Network_Title
            
            Our_Network_Image {
              alternativeText
              url
            }
        }  
      }
    }
  `);

  const network = data.glstrapi.globalModule;

  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.section
          className="our-network"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}

        >
          <Container>
            <Row>
              <Col md="12" lg="6" xl="5">
                  <div className="about-content">
                    <span className="sub-title">{network.Our_Network_Title}</span>
                    <motion.div variants={contentVariants}>
                      {parse(network.Our_Network_Content)}
                    </motion.div> 
                  </div>
              </Col>
              <Col md="12" lg="6" xl="7">
                  <motion.div variants={contentVariants}>
                    {/* <Map /> */}
                    <img src={MapImg} alt="" />
                    {/* {network.Our_Network_Image &&
                      <picture className="about-block-img">
                        <img src={network.Our_Network_Image.url} alt={network.Our_Network_Image.alternativeText} />
                      </picture>
                    } */}
                  </motion.div>
              </Col>
            </Row>
          </Container>
        </motion.section>
      )}
    </InView>
  )
}
export default OurNetwork
