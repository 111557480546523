import React, { useState } from "react";
import { Link } from "gatsby";
import "./PropertyCard.scss";
// import PlayVideo from "../../Components/Play/PlayVideo";
import Dummy from "../../../images/job-no-image.jpg"
import parse from "html-react-parser";
import _, { set } from 'lodash'
const PropertyCard = (props) => {

  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(!show);
  }

  const branchImage = (id) => {
    var image = Dummy;
    if (!_.isEmpty(id)) {
      var branch = _.find(props.branches, { branch_id: id });
      if (!_.isEmpty(branch)) {
        if (branch.image.length > 0) {
          image = branch.image[0].url;
        }
      }
    }
    return image;
  }


  return (
    <React.Fragment>
      <div className={`property-card img-zoom box-blue`} >
        <div className="properties-wrapper">
          <Link to={`/vacancy/${props.job.vacancy_id}/`}>
            <picture>
              <img src={branchImage(props.job.branch_id)} alt="img" />
            </picture>
          </Link>
        </div>
        <div className="about-properties">

          <div className="properties-info">

            <Link to={`/vacancy/${props.job.vacancy_id}/`}> <h2>{props.job.vacancy_title}</h2></Link>
            <div className="properties-icons">
              <span className="text">
                <i className="icon-map-pin-light"></i>
                <span className="sm-text">{props.job.vacancy_location}</span>
              </span>

            </div>
            <div className="properties-icons salary">
              <span className="text d-flex">
                <i className="icon-currency"></i>
                {/* <i className="icon-pin"></i> */}
                <span className="sm-text">{props.job.vacancy_salary_text}</span>
              </span>

            </div>
            <p>{parse(props.job.vacancy_short_description)}</p>
          </div>


        </div>

      </div>
    </React.Fragment>
  );
};

export default PropertyCard;
