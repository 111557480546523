import React from "react"
import { useMatch } from "@reach/router"
import { graphql } from "gatsby"
import Modules from "../components/modules"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "../scss/bootstrap-overrides.scss"
import "../scss/global.scss"
import "../scss/grunticon.scss"
import "../scss/custom.scss"
import "../scss/career-custom.scss"
import { LATEST_NEWS_PAGE_URL } from "../components/common/site/constants";
// import NewsDetailsPage from "../components/News/NewsDetails"
const DefaultTemplate = (props) => {
  const GQLPage = props.data.glstrapi?.article
  const Page_Modules = props.data.glstrapi?.article?.Modules
  let custom_css = ""
  let additional_class = ""
  if (GQLPage.Custom_CSS_Class) {
    custom_css = GQLPage.Custom_CSS_Class
  }
  if (GQLPage.Layout === "Staticpage") {
    additional_class = "static-page-detail"
  }
  if (GQLPage.Layout === "Default") {
    additional_class = "landing-page"
  }
  return (
    <div className={`${custom_css} ${additional_class}`}>
      <Modules page={GQLPage} modules={Page_Modules} {...props} />
    </div>
  )
}
export default DefaultTemplate

export const pageQuery = graphql`
  query defaultQuery($id: ID!) {
    glstrapi {
      article(id: $id, publicationState: LIVE) {
        Banner_CTA_1_Label
        Banner_CTA_1_Label_Heading
        Banner_CTA_2_Label
        Banner_CTA_2_Label_Heading
        Banner_Content
        Custom_CSS_Class
        Layout
        Meta_Description
        Meta_Title
        Pagename
        Show_Brands_Logo
        Show_Reviews
        id
        Choose_Menu {
          id
          URL
        }
        Banner_CTA_1_Link {
          id
        }
        Banner_CTA_2_Link {
          id
        }
        Video_URL
        Banner_Image {
          url
          alternativeText
          url_sharp {
            childImageSharp {
              gatsbyImageData(
                formats: WEBP
                width: 1800
                quality: 90
                layout: CONSTRAINED
                transformOptions: { cropFocus: CENTER, fit: COVER }
              ) 
            }
          }
        }
        Modules {
          ... on GLSTRAPI_ComponentModulesIntro {
            id
            Intro_CTA_Label
            Intro_Content
            Intro_Title
            Intro_Video_URL
            Intro_Image {
              alternativeText
              url
              url_sharp {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 700, maxHeight: 550) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            Intro_CTA_Link {
              id
            }
          }
          ... on GLSTRAPI_ComponentModulesIntroWithoutImage {
            id
            Intro_CTA_Label
            Intro_Content
            Intro_Heading
            Intro_Title
            Intro_CTA_Link {
              id
            }
          }
          ... on GLSTRAPI_ComponentModulesShowHelpModule {
            id
            Select_Module {
              CTA_1_Label
              CTA_2_Label
              Content
              Name
              Show_Chart
              CTA_1_Link {
                id
              }
              CTA_2_Link {
                id
              }
              Select_Form {
                Select
              }
            }
          }
          ... on GLSTRAPI_ComponentModulesGlobalModules {
            Choose_Module
          }
          ... on GLSTRAPI_ComponentModulesServicesContentBlock {
            Add_New_Block {
              Title
              Content
              CTA_Label
              CTA_Link {
                id
              }
              Image {
                alternativeText
                url
                url_sharp {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 700) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on GLSTRAPI_ComponentModulesStatistics {
            Statistics_Title
            Statistics_Description
            Add_Stats {
              Count
              Details
              Arrow
            }
          }
          ... on GLSTRAPI_ComponentModulesStaffStories {
            id
            Title
            Description
            Add_Staff_Stories {
              Video_URL
              id
              Image {
                alternativeText
                url
                url_sharp {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 440) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ... on GLSTRAPI_ComponentModulesFaqSSection {
            FAQ
            Title
            Description
            Select_FAQs {
              Answer
              Question
            }
          }
          ... on GLSTRAPI_ComponentComponentsStaticContent {
            id
            Add_Static_Content
            Style
            Show_Sidebar
            Sidebar_Custom_CTA_Label
            Sidebar_Custom_CTA_Link {
              id
              URL
            }
          }
          ... on GLSTRAPI_ComponentModulesBenefitsPointsWithIcon {
            Benefits_Intro
            Add_Benefit {
              Content
              Icon
              or_Icon_Image {
                url
                alternativeText
              }
            }
          }
          ... on GLSTRAPI_ComponentModulesCollections {
            Select_Collection
          }
          ... on GLSTRAPI_ComponentModulesFamilyOfBrands {
            id
            Family_Of_Brands_Section {
              _id
              CTA_Label
              Brand_Section_Content
              Brand_Section_Heading
              Image {
                alternativeText
                url
              }
              CTA_Link {
                id
                URL
                Label
              }
            }
            Brand_Title
            Brand_Heading
          }
          ... on GLSTRAPI_ComponentModulesGlobalModules {
            Choose_Module
          }
          ... on GLSTRAPI_ComponentModulesShowFeedStatistics {
            Stats_Title
          }
          ... on GLSTRAPI_ComponentModulesLatestNews {
            Latest_News_Heading
            Latest_News_Intro_Content
          }
          ... on GLSTRAPI_ComponentModulesGuidesSection {
            Guides_Heading
            Guides_Title
            More_Guides_Link {
              Details_Page_URL {
                id
              }
            }
            Select_Guides_here {
              Title
              URL
              Tile_Image {
                alternativeText
                url
              }
              Category {
                Details_Page_URL {
                  id
                  URL
                }
              }
            }
          }
          ... on GLSTRAPI_ComponentModulesAccreditation {
            Add_Accreditation {
              Title
              URL
              Content
              Image {
                url
                alternativeText
              }
            }
          }
        }
      }
    }
  }
`
